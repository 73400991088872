import React, {Component, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AOS from 'aos';
import "./TheEvent/assets/css/style.css";
import './TheEvent/assets/vendor/aos/aos.css';
import "./TheEvent/assets/vendor/glightbox/css/glightbox.min.css";
import "./TheEvent/assets/vendor/swiper/swiper-bundle.min.css";
import "./TheEvent/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./TheEvent/assets/vendor/bootstrap/css/bootstrap.min.css";
import "./TheEvent/assets/vendor/aos/aos.css";

import about from './images/ABOUT.PNG';
import about1 from './images/ABOUT4-1.PNG';
import about2 from './images/ABOUT4-2.png';
import about3 from './images/ABOUT4-3.png';
import about4 from './images/ABOUT4-4.png';
import footer from './images/footer.png';
import headerLogo from './images/header-logo.png';
import headerAbout from './images/header-about-1.png';
import headerRoadmap from './images/header-roadmap-1.png';
import joinyolo from './images/JOINTHEYOLO.png';
import page1 from './images/page1-1.png';
import pageArrow from './images/page1-arrow.png';
import pageButton from './images/page1-button.png';
import pageLogo from './images/page1-logo.png';
import roadmap from './images/ROADMAP.png';
import roadmap1 from './images/ROADMAP5-1.png';
import roadmap2 from './images/ROADMAP5-2.png';
import roadmap3 from './images/ROADMAP5-3.png';
import roadmap4 from './images/ROADMAP5-4.png';
import roadmap5 from './images/ROADMAP5-5.png';
import aptosLogo from './images/bnb.png';
import aptosLogo2 from './images/bnb3.png';


export default function Page(props) {
    useEffect(() => {
    AOS.init({
      duration : 500
    })
    });

    return(
        <div>
            
  <header id="header" className="d-flex align-items-center ">
    <div style={{filter:"brightness(160%)"}} className="container-fluid container-xxl d-flex align-items-center">

      <div id="logo" className="me-auto">
        <h1><a href="/"><img className="imgLogo" src={headerLogo}/></a><img className="imgAptos" src={aptosLogo}/></h1>
        <a href="index.html" className="nav-link scrollto"></a>
      </div>

      <nav id="navbar" className="navbar order-last order-lg-0">
        <ul>
          
        <li><a href="https://buzzedapeyoloclub.s3.ap-northeast-2.amazonaws.com/BRRR+WHITE+PAPER+V1.pdf" target="_blank" rel="noopener noreferrer" style={{color:"white",textDecoration:"none"}}>WHITE PAPER</a></li>
          <li><a class="nav-link scrollto" href="#about"><img className="imgAbout" src={headerAbout}/></a></li>
          <li><a class="nav-link scrollto" href="#Roadmap"><img className="imgRoadmap" src={headerRoadmap}/></a></li>
          <li><a class="nav-link scrollto" href="https://twitter.com/Brrrayc" target="_blank" rel="noopener noreferrer"><i class="bi bi-twitter"></i></a></li>
          </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>
              
          {/* {!wallet.connected ? (
      <WalletDialogButton className="buy-tickets scrollto">Connect Wallet</WalletDialogButton>
          ) : (
            <div/>
          )} */}
    </div>
  </header>
  
  <main id="main">
  <section id="hero">
    <div className="hero-container" data-aos="zoom-in" data-aos-delay="100">
        <div class="row">      
    </div>
        <h1><img className="hero-img1" src={pageLogo}/></h1>
        <h1><img className="hero-img2" src={pageButton}/></h1>
        <h1><img className="hero-img3" src={page1}/></h1>
        <h1><a className="scrollto" href="#about"><img className="hero-img4" src={pageArrow}/></a></h1>
    </div>
  </section>

    <section id="about2">
      <div class="container" data-aos="fade-up">
        <div class="row" style={{display:"block"}}>
            
        <img src={joinyolo}/>

        </div>
      </div>
    </section>
    
    <section id="about">
      <div class="container" data-aos="fade-up" >
        <div class="col">       
                <img className="about-img1" src={about}/>
            <div class="row-lg-3 road">
                <img className="about-img2" src={about1}/>
            </div>
            <div class="row-lg-3 road">
                <img className="about-img3" src={about2}/>
            </div>
            <div class="row-lg-3 road">
                <img className="about-img3" src={about3}/>
            </div>
            <div class="row-lg-3 road">
                <img className="about-img3" src={about4}/>
            </div>

        </div>
      </div>
    </section>
    
    <section id="Roadmap">
      <div class="container" data-aos="fade-up" >
        <div class="col">       
                <h1><a href="/"><img className="roadmap-imgLogo" src={roadmap}/></a></h1>
            <div className="roadmap-h2" style={{marginLeft: "10%",marginTop: "10%",width: "60%", marginBottom: "2%", textAlign: "left"}} class="row-lg-3 road">
                <h2>10% - BUZZED APE YOLO CLUB GRAND OPEN!</h2>
                <h2>20% - $BRRR COIN AIR DROP</h2>
                <h2>50% -  $BRRR COIN DEX LISTING</h2>
                <h2>80% - $BRRR COIN HOLDER, BUZZED APE CLUB NFT AIR DROP</h2>
                <h2>100% - BRRR~ BRRR~ MEME POWER READY TO PHASE 2</h2>
            </div>
            <div class="row-lg-3 road">
                <img className="roadmap-img1" src={roadmap1}/>
            </div>
            <div class="row-lg-3 road">
                <img className="roadmap-img2" src={roadmap2}/>
            </div>
            <div class="row-lg-3 road">
                <img className="roadmap-img2" src={roadmap3}/>
            </div>
            <div class="row-lg-3 road">
                <img className="roadmap-img2" src={roadmap4}/>
            </div>
            <div class="row-lg-3 road">
                <img className="roadmap-img2" src={roadmap5}/>
            </div>

        </div>
        
      </div>
    </section>
  </main>

  <footer id="footer"style={{marginTop: "10%"}}>
    <div className="footer-top">
      <div className="container">
        <div className="row">

          <div className="footer-info"style={{marginTop: "10%"}}>
            {/* <img src={openseaImg} alt="TheEvenet"/> */}
            <p><img className="imgAptos" src={aptosLogo2}/></p>
            <p>BUZZED APE YOLO CLUB © 2023 All rights reserved.</p>
          </div>

            <div className="social-links">
              <a href="https://twitter.com/Brrrayc" className="twitter" target="_blank" rel="noopener noreferrer"><i className="bi bi-twitter"></i></a>
            </div>
        </div>
      </div>
    </div>

  </footer>

  <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>


  <script src="assets/vendor/aos/aos.js"></script>
  <script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="assets/vendor/php-email-form/validate.js"></script>


  <script src="assets/js/main.js"></script>
        </div>
    );

}
