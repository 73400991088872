import logo from './logo.svg';
import './App.css';
import Page from "./Page";
import backgroundImg from './images/background.png'

function App() {
  return (
    <div className="App">
      <Page></Page>
    </div>
  );
}

export default App;
